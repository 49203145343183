// @generated by protoc-gen-connect-query v1.4.2 with parameter "target=ts"
// @generated from file fijoy/v1/transaction.proto (package fijoy.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { Empty, MethodIdempotency, MethodKind } from "@bufbuild/protobuf";
import { CreateTransactionRequest, DeleteTransactionRequest, GetTransactionRequest, GetTransactionsByAccountRequest, Transaction, TransactionList, UpdateTransactionRequest } from "./transaction_pb.js";

/**
 * @generated from rpc fijoy.v1.TransactionService.CreateTransaction
 */
export const createTransaction = {
  localName: "createTransaction",
  name: "CreateTransaction",
  kind: MethodKind.Unary,
  I: CreateTransactionRequest,
  O: Transaction,
  service: {
    typeName: "fijoy.v1.TransactionService"
  }
} as const;

/**
 * @generated from rpc fijoy.v1.TransactionService.GetTransaction
 */
export const getTransaction = {
  localName: "getTransaction",
  name: "GetTransaction",
  kind: MethodKind.Unary,
  I: GetTransactionRequest,
  O: Transaction,
      idempotency: MethodIdempotency.NoSideEffects,
  service: {
    typeName: "fijoy.v1.TransactionService"
  }
} as const;

/**
 * @generated from rpc fijoy.v1.TransactionService.GetTransactionsByAccount
 */
export const getTransactionsByAccount = {
  localName: "getTransactionsByAccount",
  name: "GetTransactionsByAccount",
  kind: MethodKind.Unary,
  I: GetTransactionsByAccountRequest,
  O: TransactionList,
      idempotency: MethodIdempotency.NoSideEffects,
  service: {
    typeName: "fijoy.v1.TransactionService"
  }
} as const;

/**
 * @generated from rpc fijoy.v1.TransactionService.GetTransactions
 */
export const getTransactions = {
  localName: "getTransactions",
  name: "GetTransactions",
  kind: MethodKind.Unary,
  I: Empty,
  O: TransactionList,
      idempotency: MethodIdempotency.NoSideEffects,
  service: {
    typeName: "fijoy.v1.TransactionService"
  }
} as const;

/**
 * @generated from rpc fijoy.v1.TransactionService.UpdateTransaction
 */
export const updateTransaction = {
  localName: "updateTransaction",
  name: "UpdateTransaction",
  kind: MethodKind.Unary,
  I: UpdateTransactionRequest,
  O: Transaction,
  service: {
    typeName: "fijoy.v1.TransactionService"
  }
} as const;

/**
 * @generated from rpc fijoy.v1.TransactionService.DeleteTransaction
 */
export const deleteTransaction = {
  localName: "deleteTransaction",
  name: "DeleteTransaction",
  kind: MethodKind.Unary,
  I: DeleteTransactionRequest,
  O: Empty,
  service: {
    typeName: "fijoy.v1.TransactionService"
  }
} as const;
