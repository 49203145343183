// @generated by protoc-gen-connect-query v1.4.2 with parameter "target=ts"
// @generated from file fijoy/v1/profile.proto (package fijoy.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { Empty, MethodIdempotency, MethodKind } from "@bufbuild/protobuf";
import { CreateProfileRequest, Profile, UpdateProfileRequest } from "./profile_pb.js";

/**
 * @generated from rpc fijoy.v1.ProfileService.CreateProfile
 */
export const createProfile = {
  localName: "createProfile",
  name: "CreateProfile",
  kind: MethodKind.Unary,
  I: CreateProfileRequest,
  O: Profile,
  service: {
    typeName: "fijoy.v1.ProfileService"
  }
} as const;

/**
 * @generated from rpc fijoy.v1.ProfileService.GetProfile
 */
export const getProfile = {
  localName: "getProfile",
  name: "GetProfile",
  kind: MethodKind.Unary,
  I: Empty,
  O: Profile,
      idempotency: MethodIdempotency.NoSideEffects,
  service: {
    typeName: "fijoy.v1.ProfileService"
  }
} as const;

/**
 * @generated from rpc fijoy.v1.ProfileService.UpdateProfile
 */
export const updateProfile = {
  localName: "updateProfile",
  name: "UpdateProfile",
  kind: MethodKind.Unary,
  I: UpdateProfileRequest,
  O: Profile,
  service: {
    typeName: "fijoy.v1.ProfileService"
  }
} as const;

/**
 * @generated from rpc fijoy.v1.ProfileService.DeleteProfile
 */
export const deleteProfile = {
  localName: "deleteProfile",
  name: "DeleteProfile",
  kind: MethodKind.Unary,
  I: Empty,
  O: Empty,
  service: {
    typeName: "fijoy.v1.ProfileService"
  }
} as const;
