// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file fijoy/v1/account.proto (package fijoy.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * @generated from enum fijoy.v1.AccountType
 */
export enum AccountType {
  /**
   * @generated from enum value: ACCOUNT_TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: ACCOUNT_TYPE_LIQUIDITY = 1;
   */
  LIQUIDITY = 1,

  /**
   * @generated from enum value: ACCOUNT_TYPE_INVESTMENT = 2;
   */
  INVESTMENT = 2,

  /**
   * @generated from enum value: ACCOUNT_TYPE_PROPERTY = 3;
   */
  PROPERTY = 3,

  /**
   * @generated from enum value: ACCOUNT_TYPE_RECEIVABLE = 4;
   */
  RECEIVABLE = 4,

  /**
   * @generated from enum value: ACCOUNT_TYPE_LIABILITY = 5;
   */
  LIABILITY = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(AccountType)
proto3.util.setEnumType(AccountType, "fijoy.v1.AccountType", [
  { no: 0, name: "ACCOUNT_TYPE_UNSPECIFIED" },
  { no: 1, name: "ACCOUNT_TYPE_LIQUIDITY" },
  { no: 2, name: "ACCOUNT_TYPE_INVESTMENT" },
  { no: 3, name: "ACCOUNT_TYPE_PROPERTY" },
  { no: 4, name: "ACCOUNT_TYPE_RECEIVABLE" },
  { no: 5, name: "ACCOUNT_TYPE_LIABILITY" },
]);

/**
 * @generated from enum fijoy.v1.AccountSymbolType
 */
export enum AccountSymbolType {
  /**
   * @generated from enum value: ACCOUNT_SYMBOL_TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: ACCOUNT_SYMBOL_TYPE_CURRENCY = 1;
   */
  CURRENCY = 1,

  /**
   * @generated from enum value: ACCOUNT_SYMBOL_TYPE_CRYPTO = 2;
   */
  CRYPTO = 2,

  /**
   * @generated from enum value: ACCOUNT_SYMBOL_TYPE_STOCK = 3;
   */
  STOCK = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(AccountSymbolType)
proto3.util.setEnumType(AccountSymbolType, "fijoy.v1.AccountSymbolType", [
  { no: 0, name: "ACCOUNT_SYMBOL_TYPE_UNSPECIFIED" },
  { no: 1, name: "ACCOUNT_SYMBOL_TYPE_CURRENCY" },
  { no: 2, name: "ACCOUNT_SYMBOL_TYPE_CRYPTO" },
  { no: 3, name: "ACCOUNT_SYMBOL_TYPE_STOCK" },
]);

/**
 * @generated from message fijoy.v1.Account
 */
export class Account extends Message<Account> {
  /**
   * the standard stuff
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: fijoy.v1.AccountType account_type = 3;
   */
  accountType = AccountType.UNSPECIFIED;

  /**
   * @generated from field: bool archived = 4;
   */
  archived = false;

  /**
   * @generated from field: string symbol = 5;
   */
  symbol = "";

  /**
   * @generated from field: fijoy.v1.AccountSymbolType symbol_type = 6;
   */
  symbolType = AccountSymbolType.UNSPECIFIED;

  /**
   * @generated from field: string amount = 7;
   */
  amount = "";

  /**
   * @generated from field: string value = 8;
   */
  value = "";

  /**
   * @generated from field: string fx_rate = 9;
   */
  fxRate = "";

  /**
   * @generated from field: string balance = 10;
   */
  balance = "";

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 11;
   */
  createdAt?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp updated_at = 12;
   */
  updatedAt?: Timestamp;

  constructor(data?: PartialMessage<Account>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "fijoy.v1.Account";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "account_type", kind: "enum", T: proto3.getEnumType(AccountType) },
    { no: 4, name: "archived", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "symbol", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "symbol_type", kind: "enum", T: proto3.getEnumType(AccountSymbolType) },
    { no: 7, name: "amount", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "fx_rate", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "balance", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "created_at", kind: "message", T: Timestamp },
    { no: 12, name: "updated_at", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Account {
    return new Account().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Account {
    return new Account().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Account {
    return new Account().fromJsonString(jsonString, options);
  }

  static equals(a: Account | PlainMessage<Account> | undefined, b: Account | PlainMessage<Account> | undefined): boolean {
    return proto3.util.equals(Account, a, b);
  }
}

/**
 * @generated from message fijoy.v1.AccountList
 */
export class AccountList extends Message<AccountList> {
  /**
   * @generated from field: repeated fijoy.v1.Account items = 1;
   */
  items: Account[] = [];

  constructor(data?: PartialMessage<AccountList>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "fijoy.v1.AccountList";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "items", kind: "message", T: Account, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AccountList {
    return new AccountList().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AccountList {
    return new AccountList().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AccountList {
    return new AccountList().fromJsonString(jsonString, options);
  }

  static equals(a: AccountList | PlainMessage<AccountList> | undefined, b: AccountList | PlainMessage<AccountList> | undefined): boolean {
    return proto3.util.equals(AccountList, a, b);
  }
}

/**
 * @generated from message fijoy.v1.CreateAccountRequest
 */
export class CreateAccountRequest extends Message<CreateAccountRequest> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: fijoy.v1.AccountType account_type = 2;
   */
  accountType = AccountType.UNSPECIFIED;

  /**
   * @generated from field: string symbol = 3;
   */
  symbol = "";

  /**
   * @generated from field: fijoy.v1.AccountSymbolType symbol_type = 4;
   */
  symbolType = AccountSymbolType.UNSPECIFIED;

  constructor(data?: PartialMessage<CreateAccountRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "fijoy.v1.CreateAccountRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "account_type", kind: "enum", T: proto3.getEnumType(AccountType) },
    { no: 3, name: "symbol", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "symbol_type", kind: "enum", T: proto3.getEnumType(AccountSymbolType) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateAccountRequest {
    return new CreateAccountRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateAccountRequest {
    return new CreateAccountRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateAccountRequest {
    return new CreateAccountRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateAccountRequest | PlainMessage<CreateAccountRequest> | undefined, b: CreateAccountRequest | PlainMessage<CreateAccountRequest> | undefined): boolean {
    return proto3.util.equals(CreateAccountRequest, a, b);
  }
}

/**
 * @generated from message fijoy.v1.UpdateAccountRequest
 */
export class UpdateAccountRequest extends Message<UpdateAccountRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: optional string name = 2;
   */
  name?: string;

  /**
   * @generated from field: optional bool archived = 3;
   */
  archived?: boolean;

  constructor(data?: PartialMessage<UpdateAccountRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "fijoy.v1.UpdateAccountRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "archived", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateAccountRequest {
    return new UpdateAccountRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateAccountRequest {
    return new UpdateAccountRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateAccountRequest {
    return new UpdateAccountRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateAccountRequest | PlainMessage<UpdateAccountRequest> | undefined, b: UpdateAccountRequest | PlainMessage<UpdateAccountRequest> | undefined): boolean {
    return proto3.util.equals(UpdateAccountRequest, a, b);
  }
}

/**
 * @generated from message fijoy.v1.GetAccountRequest
 */
export class GetAccountRequest extends Message<GetAccountRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<GetAccountRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "fijoy.v1.GetAccountRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAccountRequest {
    return new GetAccountRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAccountRequest {
    return new GetAccountRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAccountRequest {
    return new GetAccountRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetAccountRequest | PlainMessage<GetAccountRequest> | undefined, b: GetAccountRequest | PlainMessage<GetAccountRequest> | undefined): boolean {
    return proto3.util.equals(GetAccountRequest, a, b);
  }
}

